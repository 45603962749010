export interface ResponseApi<T> {
  data: T;
  error: any;
  code: string;
}

export type ResponseListApi<T> = ResponseApi<{
  content: T[];
  page: number;
  size: number;
  total: number;
}>;

export interface KeyedValue {
  title: string;
  value: string;
  textColor?: string;
  bgColor?: string;
}

export interface IdName {
  id: string;
  name: string;
}

export interface ApiBasicParams {
  keyword?: string;
  page?: number;
  size?: number;
  sorts?: string;
}

export interface StatusOption {
  id: string;
  code: string;
  name: string;
  color: string;
  position: number;
  backgroundColor: string;
}

export enum GENDER {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  OTHER = 'OTHER',
  UNKNOWN = 'UNKNOWN'
}

export interface Statistic {
  code: string;
  name: string;
  count: number;
  color?: string | null;
  backgroundColor?: string | null;
}

export type ModeAction = 'CREATE' | 'EDIT' | 'VIEW';
